import { toLine } from '../../../utils/transformCssPropertyName'
import _ from 'lodash'

export default async function staticRender({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { align, boxWidth = 100, href, imgSrc = 'https://df5apg8r0m634.cloudfront.net/images/4ca7c2e83a08d533a1d3997c514b232c.jpg', styles = {} } = props

  const imgTag = await renderPictureTag(imgSrc)

  const result = {
    styles: [
      {
        name: `.pt-img-${page_id}`,
        content: `
          .pt-img-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
          .pt-img-${page_id} img {
            width: 100%;
          }
        `
      },
      {
        name: `pt-img-box-${id}`,
        content: `
          .pt-img-box-${id} {
            width: ${boxWidth}%;
          }
        `
      },
      {
        name: 'pt-img-href-box',
        content: `
          .pt-img-href-box {
            font-size: 0;
            cursor: pointer;
          }
        `
      },
      {
        name: `pt-img-href-box-${id}`,
        content: `
          .pt-img-href-box-${id} { width: ${boxWidth}%; }
        `
      },
      {
        name: `.pt-img-${id} img`,
        content: `
          .pt-img-${id} img {
            ${Object.keys(styles).reduce((pre, css) => `${pre} ${toLine(css)}:${styles[css]};`, '')}
          }
        `
      }
    ],
    html: `
      <div class="pt-img-${page_id} pt-img-${id}">
        ${
          href
            ? `
          <a class="pt-img-href-box pt-img-href-box-${id}" href="${href}" target="_blank">
            ${imgTag}
          </a>
        `
            : `
          <div class="pt-img-box-${id}">
            ${imgTag}
          </div>
        `
        }
      </div>
    `
  }
  if (align) {
    result.styles.push({
      name: `.pt-img-${id}`,
      content: `
          .pt-img-${id} { justify-content: ${align}; }
        `
    })
  }
  return result
}

export const IMG_CDN = 'https://df5apg8r0m634.cloudfront.net'

// 检查是否适用于 webp
export function isWebpCompatible(imgSrc) {
  if (!imgSrc || !_.isString(imgSrc)) return false;
  return imgSrc?.startsWith(IMG_CDN) && /\.(png|jpg)$/.test(imgSrc);
}

// 将图片 URL 替换为 webp 格式
export function replaceWithWebp(imgSrc) {
  if (!imgSrc || !_.isString(imgSrc)) return '';
  return imgSrc?.replace(/\.(png|jpg)$/, '.webp');
}

// 生成 picture 标签
export async function renderPictureTag(imgSrc) {
  let result = `
  <img src="${imgSrc}" />
`
  if (imgSrc && isWebpCompatible(imgSrc)) {
    let srcset = replaceWithWebp(imgSrc)
    let webpValid = await isWebpValid(srcset)
    if (webpValid) {
      result = `
          <picture>
            <source src="${srcset}" type="image/webp">
            <img src="${imgSrc}" />
          </picture>
        `
    }
  }

  // 如果不是 webp 兼容或者 webp 无效，直接返回普通 img 标签
  return result
}

export function isWebpValid(webpSrc) {
  return new Promise(resolve => {
    const img = new Image()
    img.src = webpSrc
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}
