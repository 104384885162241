import { axiosPost } from './axiosCore'
export async function buildWebp(params) {
  return axiosPost(`/apisaas/upload/format`, params)
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}
