import { toLine } from "../../../utils/transformCssPropertyName";
const ExpiryDate = {
  en: "Expiry Date:",
  es: "Fecha de caducidad: ",
  de: "Gültig bis:",
  fr: "Date d’expiration :",
  pt: "Data de expiração:",
  jp: "有効期限",
  ro: "Data de expirare:",
  pl: "Data ważności: ",
  sa: "تاريخ الانتهاء:",
  nl: "Vervaldatum:",
  sv: "Utgångsdatum:",
  el: "Ημερομηνία λήξης:",
};
const CopyText = {
  en: "Copy code and choose glasses",
  es: "Copia el código y aplica la oferta",
  de: "Code kopieren und Brille auswählen",
  fr: "Copiez le code et choisissez les lunettes",
  it: "Coppia",
  pt: "Copiar o código e aplicar a oferta",
  jp: "コードをコピーしてメガネを選ぶ",
  ro: "Copiați codul și alegeți ochelarii",
  pl: "Skopiuj kod i wybierz okulary",
  sa: "انسخ الرمز واختر النظارات",
  nl: "Kopieer de code en kies een bril",
  sv: "Kopiera koden och välj glasögon",
  el: "Αντιγράψτε τον κωδικό και επιλέξτε γυαλιά",
};
const Loading  = {
  en: "Loading...",
  es: "Cargando...",
  de: "Laden...",
  fr: "Chargement...",
  it: "Caricamento...",
  pt: "Carregando...",
  jp: "読み込み中...",
  ro: "Se încarcă...",
  pl: "Ładowanie...",
  sa: "جارٍ التحميل...",
  nl: "Laden...",
  sv: "Laddar...",
  el: "Φόρτωση...",
}
export default function staticRender({ config, page_id = 0 }) {
  let { id, props = {} } = config;
  id = `${page_id}-${id}`;
  const {
    notNeedEmail = false,
    buttonText = "Get Code",
    hoverButtonText = "Get Code",
    couponCode = "",
    showExperienceTime,
    codeTitle = "Get Code",
    hasnoNumList,
    ruleList = [],
    title,
    placeholder = "Enter your email address to get code",
    lang = "en",
    btnBgColor = "#26ABFF", //按钮颜色
    fixedExpTime, //固定code 手动输入过期时间
  } = props;

  const result = {
    styles: [
      {
        name: `.pt-coupon-${page_id}`,
        content: `
          .pt-coupon-${page_id} {
          }
          .pt-coupon-form-email-input {
            display: block;
            width: 482px;
            outline: none;
            border: 2px solid rgba(228, 228, 228, 1);
            box-sizing: border-box;
            line-height: 1;
            color: #333;
            font-size: 14px;
            margin: 0 auto 20px;
            font-family: Roboto-Regular;
            text-align: center;
            height: 34px;
            /* placeContent: { color: #999; } */
          }
          .pt-coupon-form-submit-btn {
            border: none;
            font-size: 18px;
            color: #fff;
            line-height: 1;
            padding: 16px 65px;
            margin: 0 auto;
            display: block;
            border-radius: 4px;
          }
          @keyframes rotate {
            from {
              transform: rotate(-90deg);
              display: block;
            }
            to {
              transform: rotate(0deg);
            }
          }
          @keyframes rotateBack {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-90deg);
              display: none;
            }
          }
          .pt-coupon-fixed-btn.pc_up_show {
            animation: rotate 0.5s;
            opacity: 1;
            z-index: 99;
          }
          .pt-coupon-fixed-btn.pc_up_hide {
            animation: rotateBack 0.5s;
            opacity: 0;
            z-index: -1;
          }
          .pt-coupon-fixed-btn {
            position: fixed;
            right: 0;
            top: 50%;
            transform: rotateZ(0);
            transition: all 0.5s;
            background: #000;
            color: #fff;
            padding: 10px;
            cursor: pointer;
            transform-origin: top right;
            animation-fill-mode: forwards;
            opacity: 0;
            z-index: -1;
            max-width: 198px;
            word-break: break-all;
            /* white-space: nowrap; */
            white-space: break-spaces;
            text-align: center;
            box-sizing: border-box;
          }
          .pt-coupon-modal-form-title {
            font-size: 24px;
            text-align: center;
            padding: 10px 0;
            font-family: Roboto-Medium;
          }
          .pt-coupon-modal-form {
            padding-bottom: 20px;
          }
          .pt-coupon-code-info {
            text-align: center;
            width: 579px;
          }
          .pt-code-box {
            width: 284px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            margin: 30px auto 0;
            font-size: 28px;
            background: rgba(255, 255, 255, 1);
            border: 2px solid rgba(228, 228, 228, 1);
            color: #979494;
          }
          .pt-time-box {
            text-align: center;
            line-height: 1;
            color: #333;
            font-size: 14px;
            padding: 16px 0 10px;
          }
          .pt-copy-btn {
            line-height: 1;
            color: #26abff;
            font-size: 18px;
            cursor: pointer;
            font-family: Roboto-Medium;
          }
          .pt-big-box {
            width: 74%;
            border-top: 1px solid #ccc;
            margin: 20px auto 0;
            padding-bottom: 20px;
          }
          .pt-big-box-title {
            color: #333;
            font-size: 24px;
            padding: 5px 0;
            line-height: 1.5;
          }
          .pt-big-box-ul {
            text-align: left;
            margin: 0;
            padding: 0;
          }
          .pt-big-box-dot {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #000;
            margin-right: 5px;
            margin-bottom: 2px;
          }
          .pt-big-box-num {
            display: inline-block;
            margin-right: 5px;
          }
          .pt-big-box-li {
            font-size: 14px;
            line-height: 20px;
            list-style: none;
            word-break: break-word;
          }
        `,
      },
      {
        name: `.pt-coupon-${id}`,
        content: `
          .pt-coupon-form-submit-btn-${id} {
            background-color: ${btnBgColor};
          }
          .pt-coupon-fixed-btn-${id} {
            background-color: ${btnBgColor};
          }
        `,
      },
    ],
    html: `
      <div class="pt-coupon-${page_id} pt-coupon-${id}">
        ${
          !notNeedEmail
            ? `
          <!-- 最上方 用户一眼就能看到的那个表单 -->
          <form name="pt-coupon-form-${id}">
            <input
              class="pt-coupon-form-email-input pt-coupon-top-form-email-input-${id}"
              type="email"
              placeholder="${placeholder}"
            />
            <button
              class="pt-coupon-form-submit-btn pt-coupon-form-submit-btn-${id}"
              type="submit"
            >${buttonText}</button>
          </form>
        `
            : ""
        }

        <!-- 悬浮按钮 -->
        <div class="pt-coupon-fixed-btn pt-coupon-fixed-btn-${id} pc_up_hide">${hoverButtonText}</div>

        <!-- 弹窗领取 -->
        <div class="dialog-box pt-coupon-form-modal-${id}">
          <div class="dialog">
            <div class="dialog-body">
              <svg viewBox="0 0 1000 1000" class="dialog-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div>
                <!-- 弹窗中的表单 -->
                <div>
                  ${
                    title
                      ? `
                    <div class="pt-coupon-modal-form-title">${title}</div>
                  `
                      : ""
                  }
                  <form class="pt-coupon-modal-form pt-coupon-modal-form-${id}">
                    <input
                      class="pt-coupon-form-email-input pt-coupon-modal-form-email-input-${id}"
                      type="email"
                      placeholder="${placeholder}"
                    />
                    <button
                      class="pt-coupon-form-submit-btn pt-coupon-form-submit-btn-${id}"
                      type="submit"
                    >${buttonText}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 显示code -->
        <div class="dialog-box pt-coupon-code-modal-${id}">
          <div class="dialog">
            <div class="dialog-body">
              <svg viewBox="0 0 1000 1000" class="dialog-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div>
                <!-- 弹窗展示code相关信息 -->
                <div class="pt-coupon-code-info">
                  <div class="pt-code-box pt-code-box-${id}"><b>{{CODE}}</b></div>
                  <div class="pt-time-box pt-time-box-${id}">{{EXPIRE_DATE_TIME}}</div>
                  <div class="pt-copy-btn pt-copy-btn-${id}">{{COPY_TEXT}}</div>
                  <div class="pt-big-box">
                    <div class="pt-big-box-title"><b>${codeTitle}</b></div>
                    <ul class="pt-big-box-ul">
                      ${ruleList
                        .map((item, index) => {
                          return `
                          <li class="pt-big-box-li">${
                            hasnoNumList
                              ? `<div class="pt-big-box-dot"></div>`
                              : `<div class="pt-big-box-num">${
                                  index + 1
                                }.</div>`
                          }${item}</li>
                        `;
                        })
                        .join(" ")}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 提示msg -->
        <div class="dialog-box pt-coupon-msg-modal-${id}">
          <div class="dialog">
            <div class="dialog-body">
              <svg viewBox="0 0 1000 1000" class="dialog-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div class="dialog-coupon-msg">
                {{MSG}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="pc_hover_button_line_${id}" style="height:${
      notNeedEmail ? "20" : "0"
    }px;"></div>
      <script>
        (function() {
          var fixedBtn = document.querySelector('.pt-coupon-fixed-btn-${id}'); // 悬浮按钮
          var emailFromTopForm; // 用户输入的邮箱
          var emailFromModalForm; // 用户输入的邮箱

          var apiResult; // 领取code接口返回的信息

          function getElementTopLeft(obj) {
            var top = 0;
            while (obj) {
                top += obj.offsetTop;
                obj = obj.offsetParent;
            }
            return { top };
          }

          function handleSubmit(lang, email, elBtn, cb) {
            function disabledButton(el) {
              el.setAttribute('disabled', 'true');
              el.style.background = '#ccc';
              el.innerText = '${Loading[lang]}';
            }
            function activeButton(el) {
                el.removeAttribute('disabled');
                el.style.background = oldBg;
                el.innerText = oldBtnText;
            }

            // var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,6})$/;
            if (!email) return false
            var oldBtnText = elBtn.innerText
            var oldBg = getComputedStyle(elBtn)['background-color'];

            var scheme_id = '826'
            var group_scheme_id = '380'
            var argsEl = document.querySelector(".UP_config_html")
            if (argsEl) {
              scheme_id = argsEl.getAttribute('data-scheme_id')
              group_scheme_id = argsEl.getAttribute('data-group_scheme_id')
            }
            var postUrl = '/remoteapi/common/get/coupon';
            var xhr = new XMLHttpRequest();
            xhr.open('POST', postUrl);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.setRequestHeader("accept-language", "${lang}");
            xhr.send(\`email_address=\${email}&scheme_id=\${scheme_id}&group_scheme_id=\${group_scheme_id}\`);
            disabledButton(elBtn);
            xhr.onreadystatechange = function () {
              if (xhr.readyState == 4) {
                  activeButton(elBtn);
                  if (xhr.status === 200) {
                      const data = JSON.parse(xhr.responseText);
                      cb && cb(data);
                  }
              };
            }
          }

          function openCodeModalAndClearEmail(coupon_code, expire_date, clearEmail, emailSource) {
            apiResult = { code: coupon_code }
            document.querySelector('.pt-code-box-${id}').querySelector('b').innerText = coupon_code
            document.querySelector('.pt-copy-btn-${id}').innerText = '${
      CopyText[lang]
    }'
            ${(() => {
              if (showExperienceTime) {
                return `
                  if (expire_date) {
                    document.querySelector('.pt-time-box-${id}').innerText = '${ExpiryDate[lang]} ' + expire_date
                  } else {
                    document.querySelector('.pt-time-box-${id}').style.display = 'none'
                  }
                `;
              } else if (fixedExpTime) {
                return `
                  if (!expire_date) {
                    document.querySelector('.pt-time-box-${id}').innerText = '${ExpiryDate[lang]} ${fixedExpTime}'
                  } else {
                    document.querySelector('.pt-time-box-${id}').style.display = 'none'
                  }
                `;
              } else {
                return `document.querySelector('.pt-time-box-${id}').style.display = 'none'`;
              }
            })()}
            document.querySelector('.pt-coupon-code-modal-${id}').classList.add('open')
            if (clearEmail) {
              if (emailSource === 'top') {
                emailFromTopForm = ''
                document.querySelector('.pt-coupon-top-form-email-input-${id}').value = ''
              } else if (emailSource === 'modal') {
                emailFromModalForm = ''
                document.querySelector('.pt-coupon-modal-form-email-input-${id}').value = ''
              }
            }
          }

          function afterCallApi(data, source) {
            const { coupon_code = '', expire_date = '', special_code, special_expire_date, status, msg } = data;
            if (coupon_code && expire_date) {
              openCodeModalAndClearEmail(coupon_code, expire_date, true, source)
            } else if (status == 0 && special_code && special_expire_date) {
              openCodeModalAndClearEmail(special_code, special_expire_date, true, source)
            } else if (msg) {
              document.querySelector('.pt-coupon-msg-modal-${id} .dialog-coupon-msg').innerText = msg
              document.querySelector('.pt-coupon-msg-modal-${id}').classList.add('open')
            }
          }

          ${
            !notNeedEmail
              ? `
            // top form 用户输入邮箱事件
            document.querySelector('.pt-coupon-top-form-email-input-${id}').onchange = function(e) {
              emailFromTopForm = e.target.value
            }
            // 用户提交最上边的表单的事件
            document.querySelector('form[name="pt-coupon-form-${id}"]').onsubmit = function(e) {
              e.preventDefault();
              handleSubmit('${lang}', emailFromTopForm, e.target.querySelector('button'), function(data) {
                afterCallApi(data, 'top')
              })
            }
          `
              : ""
          }

          // modal 表单提交事件
          document.querySelector('.pt-coupon-modal-form-${id}').onsubmit = function(e) {
            e.preventDefault();
            handleSubmit('${lang}', emailFromModalForm, e.target.querySelector('button'), function(data) {
              document.querySelector('.pt-coupon-form-modal-${id}').classList.remove('open')
              afterCallApi(data, 'modal')
            })
          }

          // 悬浮按钮点击事件监听
          fixedBtn.onclick = function() {
            ${
              notNeedEmail
                ? `
              openCodeModalAndClearEmail('${couponCode}', null)
            `
                : `
              document.querySelector('.pt-coupon-form-modal-${id}').classList.add('open')
            `
            }
          }

          // modal form 用户输入邮箱事件
          document.querySelector('.pt-coupon-modal-form-email-input-${id}').onchange = function(e) {
            emailFromModalForm = e.target.value
          }

          // 给window绑定scroll事件监听，方便展示/隐藏悬浮按钮
          window.addEventListener('scroll', function() {
            var windowScrollTop = document.documentElement.scrollTop || document.body.scrollTop
            var flagDiv = document.querySelector('#pc_hover_button_line_${id}');
            var flagDivTop = flagDiv && getElementTopLeft(flagDiv).top || 0;
            if (windowScrollTop > flagDivTop) {
              fixedBtn.classList.remove('pc_up_hide')
              fixedBtn.classList.add('pc_up_show')
            } else {
              fixedBtn.classList.remove('pc_up_show')
              fixedBtn.classList.add('pc_up_hide')
            }
          })

          // fixed按钮点击出现的那个弹窗关闭按钮点击事件
          document.querySelector('.pt-coupon-form-modal-${id} .dialog-btn-close').onclick = function() {
            document.querySelector('.pt-coupon-form-modal-${id}').classList.remove('open');
          }

          function closeCodeModal() {
            document.querySelector('.pt-coupon-code-modal-${id}').classList.remove('open');
            document.querySelector('.pt-coupon-msg-modal-${id}').classList.remove('open')
          }
          // 领取code成功出现的弹窗关闭按钮点击事件
          document.querySelector('.pt-coupon-code-modal-${id} .dialog-btn-close').onclick = closeCodeModal
          document.querySelector('.pt-coupon-msg-modal-${id} .dialog-btn-close').onclick = closeCodeModal

          document.querySelector('.pt-copy-btn-${id}').onclick = function(e) {
            try {
              window.Copy(\`<div>\${apiResult && apiResult.code ? apiResult.code : '${couponCode}'}</div>\`)
            } catch (e) {
              console.log('copy code error -> ', e)
            }
            closeCodeModal()
          }
        })()
      </script>
    `,
  };
  return result;
}
