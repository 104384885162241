import { toLine } from '../../../utils/transformCssPropertyName'
import { renderPictureTag } from '../../pc/PcImg/StaticRender'

export default async function staticRender({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { align, boxWidth = 100, href, imgSrc = 'https://df5apg8r0m634.cloudfront.net/images/4ca7c2e83a08d533a1d3997c514b232c.jpg', styles = {} } = props

  const imgTag= await renderPictureTag(imgSrc)

  const result = {
    styles: [
      {
        name: `.pt-h5-img-${page_id}`,
        content: `
          .pt-h5-img-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
          .pt-h5-img-${page_id} img {
            width: 100%;
          }
          .pt-h5-img-href-box {
            font-size: 0;
            cursor: pointer;
          }
        `
      },
      {
        name: `pt-h5-img-box-${id}`,
        content: `
          .pt-h5-img-box-${id} {
            width: ${boxWidth}%;
          }
          .pt-h5-img-href-box-${id} { width: ${boxWidth}%; }
          .pt-h5-img-${id} img {
            ${Object.keys(styles).reduce((pre, css) => `${pre} ${toLine(css)}:${styles[css]};`, '')}
          }
        `
      },
    ],
    html: `
      <div class="pt-h5-img-${page_id} pt-h5-img-${id}">
        ${href ? `
          <a class="pt-h5-img-href-box pt-h5-img-href-box-${id}" href="${href}" target="_blank">
            ${imgTag}  
          </a>
        ` : `
          <div class="pt-h5-img-box-${id}">
            ${imgTag}  
          </div>
        `}
      </div>
    `,
  }
  if (align) {
    result.styles.push(
      {
        name: `.pt-h5-img-${id}`,
        content: `
          .pt-h5-img-${id} { justify-content: ${align}; }
        `
      }
    )
  }
  return result
}
