import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let {
    styles = {},
    isHide = false
  } = props

  const result = {
    styles: [
      {
        name: `.pt-alert-${page_id}`,
        content: `
          .pt-alert-inner {
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
          }
          .Alert_By_Tool + .Alert_By_Tool {
            display: none;
          }
        `
      },
      {
        name: `.pt-alert-${id}`,
        content: `
          .pt-alert-${id} {
            display: ${isHide && !window.isEdit ? 'none' : 'block'}
          }
          .pt-alert-inner-${id} {
            ${Object.keys(styles).map(css => `${toLine(css)}:${styles[css]};`).join(' ')}
            background-color: ${styles.backgroundColor ?? '#fff'};
          }
        `
      }
    ],
    html: `
      <div class="pt-alert-${page_id} pt-alert-${id} Alert_By_Tool">
        <div class="pt-alert-inner pt-alert-inner-${id}">
          {{CHILDREN_PLACEHOLDER}}
        </div>
      </div>
    `,
  }
  return result
}
