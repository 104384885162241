import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { align, boxWidth = 100, text, styles = {} } = props

  if (!text) {
    text = ''
  }

  const defaultStyles = {
    'fontSize': '14px',
    'lineHeight': '1.5',
    'textAlign': 'left',
    'color': '#333'
  }

  styles = { ...defaultStyles, ...styles }

  const result = {
    styles: [
      {
        name: `.pt-text-${page_id}`,
        content: `
          .pt-text-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
        `
      },
      {
        name: `.pt-text-inner-${page_id}`,
        content: `
          .pt-text-inner-${page_id} > p {
            margin: 0;
          }
        `
      },
      {
        name: `.pt-text-inner-${id}`,
        content: `
          .pt-text-inner-${id} {
            width: ${boxWidth}%;
            ${Object.keys(styles)
              .map(css => {
                return `${toLine(css)}:${styles[css]};`
              })
              .join(' ')}
          }
        `
      }
    ],
    html: `
      <div class="pt-text-${page_id} pt-text-${id}">
        <div class="pt-text-inner-${page_id} pt-text-inner-${id}">${text}</div>
      </div>
    `
  }
  if (align) {
    result.styles.push({
      name: `.pt-text-${id}`,
      content: `
          .pt-text-${id} { justify-content: ${align}; }
        `
    })
  }
  return result
}
