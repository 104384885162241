// H5 图片
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IMG_CDN, isWebpCompatible, replaceWithWebp, isWebpValid } from '../../pc/PcImg/StaticRender'
import { buildWebp } from '../../../api/upload.js'

const HrefBox = styled.a(({ styles = {}, boxWidth, href }) => {
  return {
    fontSize: 0,
    width: `${boxWidth}%`,
    // padding:'20px 0',
    cursor: href ? 'pointer' : 'default',
    ...styles
  }
})
const Img = styled.img(({ styles = {} }) => ({
  width: '100%',
  ...styles
}))

const Container = styled.div(({ styles }) => ({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  ...styles
}))
const Box = styled.div(({ boxWidth }) => {
  return {
    width: `${boxWidth}%`
  }
})

function H5Img({ imgSrc, src, href, boxWidth = 100, align, styles }) {
  const [webpSrc, setWebpSrc] = useState('')

  useEffect(() => {
    setWebpSrc('')
    hanldeWebpImg(imgSrc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc])

  if (!imgSrc) {
    imgSrc = src || 'https://df5apg8r0m634.cloudfront.net/images/0d5faf98e226c01377ab4a8cc529a504.png'
  }

  const getImgPath = () => {
    return imgSrc.replace(`${IMG_CDN}/`, '')
  }
  //webp 请求
  const postWebp = () => {
    //分割出图片路径
    const path = getImgPath()
    if (!path) return
    const params = {
      path,
      formats: 'webp'
    }
    buildWebp(params)
      .then(res => {})
      .catch(e => {
        console.log('webp request error', e)
      })
  }

  const hanldeWebpImg = async imgSrc => {
    if (!imgSrc || !isWebpCompatible(imgSrc)) return
    const src = replaceWithWebp(imgSrc)
    let webpValid = await isWebpValid(src)
    if (webpValid) {
      setWebpSrc(src)
    } else {
      postWebp()
    }
  }

  const renderImg = () => {
    return webpSrc ? (
      <picture>
        <source src={webpSrc} type='image/webp' />
        <Img src={imgSrc} styles={styles} />
      </picture>
    ) : (
      <Img src={imgSrc} styles={styles} />
    )
  }

  return (
    <Container styles={align ? { justifyContent: align } : {}}>
      {href ? (
        <HrefBox
          href={href ? href : ''}
          boxWidth={boxWidth}
          onClick={e => {
            !href && e.preventDefault()
          }}
          target='_blank'
        >
          {renderImg()}
        </HrefBox>
      ) : (
        <Box boxWidth={boxWidth}>{renderImg()}</Box>
      )}
    </Container>
  )
}

export default H5Img
