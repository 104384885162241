import PcBaseBox from '../pageComponents/pc/PcBaseBox'
import PcRowContent from '../pageComponents/pc/PcRowContent'
import PcTitle from '../pageComponents/pc/PcTitle'
import PcText from '../pageComponents/pc/PcText'
import PcImg from '../pageComponents/pc/PcImg'
import PcButton from '../pageComponents/pc/PcButton'
import PcVideo from '../pageComponents/pc/PcVideo'
import PcSwiperImg from '../pageComponents/pc/PcSwiperImg'
import PcUserShow from '../pageComponents/pc/PcUserShow'
import PcCouponForm from '../pageComponents/pc/PcCouponForm'
import PcRule from '../pageComponents/pc/PcRule'
import PcTime from '../pageComponents/pc/PcTime'
import PcSwiper from '../pageComponents/pc/PcSwiper'
import PcSwiperItem from '../pageComponents/pc/PcSwiperItem'
import PcAlert from '../pageComponents/pc/PcAlert'
import PcAlertCoupon from '../pageComponents/pc/PcAlertCoupon'
import PCCodeContainer from '../pageComponents/pc/PCCodeContainer'
import PcProduct from '../pageComponents/pc/PcProduct'

import H5BaseBox from '../pageComponents/h5/H5BaseBox'
import H5RowContent from '../pageComponents/h5/H5RowContent'
import H5Title from '../pageComponents/h5/H5Title'
import H5Text from '../pageComponents/h5/H5Text'
import H5Img from '../pageComponents/h5/H5Img'
import H5Button from '../pageComponents/h5/H5Button'
import H5Video from '../pageComponents/h5/H5Video'
import H5SwiperImg from '../pageComponents/h5/H5SwiperImg'
import H5UserShow from '../pageComponents/h5/H5UserShow'
import H5CouponForm from '../pageComponents/h5/H5CouponForm'
import H5Rule from '../pageComponents/h5/H5Rule'
import H5Time from '../pageComponents/h5/H5Time'
import H5Swiper from '../pageComponents/h5/H5Swiper'
import H5SwiperItem from '../pageComponents/h5/H5SwiperItem'
import H5Alert from '../pageComponents/h5/H5Alert'
import H5AlertCoupon from '../pageComponents/h5/H5AlertCoupon'
import H5CodeContainer from '../pageComponents/h5/H5CodeContainer'
import H5Product from '../pageComponents/h5/H5Product'

const map = {
  PcBaseBox,
  PcRowContent,
  PcTitle,
  PcText,
  PcImg,
  PcButton,
  PcVideo,
  PcSwiperImg,
  PcUserShow,
  PcCouponForm,
  PcRule,
  PcTime,
  PcSwiper,
  PcSwiperItem,
  PcAlert,
  PcAlertCoupon,
  PCCodeContainer,
  PcProduct,
  H5BaseBox,
  H5RowContent,
  H5Title,
  H5Text,
  H5Button,
  H5Img,
  H5Video,
  H5SwiperImg,
  H5UserShow,
  H5Rule,
  H5Time,
  H5CouponForm,
  H5Swiper,
  H5SwiperItem,
  H5Alert,
  H5AlertCoupon,
  H5CodeContainer,
  H5Product
}

export default async function getHtmlByConfig({ pcFloorList = [], h5FloorList = [], page_id }) {
  const getAllComponentTypes = configList => {
    const typeSet = new Set()
    for (const config of configList) {
      typeSet.add(config.name)
      if (config.children && config.children.length) {
        const childrenTypeSet = getAllComponentTypes(config.children)
        for (const childType of childrenTypeSet.values()) {
          if (childType && childType !== 'null') {
            typeSet.add(childType)
          }
        }
      }
    }
    return typeSet
  }
  // 根据配置拿到所有用到的组件的类型（名称）
  const allComponentTypes = [...getAllComponentTypes(pcFloorList), ...getAllComponentTypes(h5FloorList)]
  const deprecatedComponentTypes = allComponentTypes.filter(it => map[it] === undefined)
  // 如果用到的组件不在上边的map中
  if (deprecatedComponentTypes.length) {
    return {
      statusCode: 1,
      msg: `部分组件已过期，需要更换：${deprecatedComponentTypes.join(',')}`
    }
  }

  // code = code.replace(/(?:(?:\r\n|\r|\n)\s*){2}/gm, '\r\n') // 去除空行
  const h5Code = await getHtml(h5FloorList, true, page_id)
  const pcCode = await getHtml(pcFloorList, false, page_id)
  let style = `<style>
          .pt-pc-el-list-${page_id} {
            display: none;
          }
          @media screen and (min-width: 768px) {
            .pt-pc-el-list-${page_id} {
              display: block;
            }
            .pt-mobile-el-list-${page_id} {
              display: none;
            }
          }
        </style>`
  let styleSingle = `<style> 
          .pt-firmooo-container-${page_id} {
            max-width: 750px;
            margin:0 auto;
          }
        </style>`
  const divStart = `<div class="pt-firmooo-container-${page_id}">`
  const divEnd = `</div>`
  return {
    statusCode: 0,
    code: `${divStart}${style}${h5Code}${pcCode}${divEnd}`,
    pcCode: `${divStart}${pcCode}${divEnd}`,
    h5Code: `${divStart}${styleSingle}${h5Code}${divEnd}`
  }
}

/**
 * 遍历配置项 得到代码和样式。最后把样式去重，和代码合在一起返回
 */
async function getHtml(configList, isMobile, page_id) {
  let code = ''
  const styleMap = {}
  // 对配置项进行循环。然后得到这个 组件和后代组件 的 样式和代码
  for (const config of configList) {
    const { styles, html } =await getCodeStyle({ config, page_id })
    styles.forEach(({ name, content }) => {
      // 这里对一些通用的样式进行去重
      if (!styleMap[name]) {
        styleMap[name] = content
      }
    })
    code += html
  }

  // 合并code
  let styleCode = Object.keys(styleMap)
    .map(key => styleMap[key])
    .join(' ')

  // dialog的通用样式
  const pcDialogStyle = `
    .dialog-box {
      position: fixed;
      display: flex;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: rgba(0, 0, 0, .3);
      visibility: hidden;
      opacity: 0;
      /*backdrop-filter: blur(3px);*/
      transition: .3s;
      cursor: initial;
    }
    .dialog-box.open {
      opacity: 1;
      z-index: 50;
      visibility: visible;
    }
    .dialog {
      display: flex;
      position: relative;
      min-width: 360px;
      margin: auto;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      max-width: calc(100vw - 20px);
      max-height: calc(100vh - 20px);
      border-radius: 3px;
      background-color: #fff;
      opacity: 0;
      transform: scale(0.5);
      transition: .3s cubic-bezier(.645, .045, .355, 1);
    }
    .dialog-box.open .dialog {
      opacity: 1;
      transform: scale(1);
    }
    .dialog-body {
      position: relative;
      box-sizing: border-box;
      display: flex;
      /* width: 33vw; */
      /* padding: 25px 25px; */
      width: 579px;
      padding: 10px 10px;
      flex: 1;
      flex-direction: column;
    }
    .dialog-btn-close {
      position: absolute;
      right: 8px;
      top: 8px;
      border: 0;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  `
  const mobileDialogStyle = `
  .dialog-h5-box {
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(0, 0, 0, .3);
    visibility: hidden;
    opacity: 0;
    /*backdrop-filter: blur(3px);*/
    transition: .3s;
    cursor: initial;
  }
  .dialog-h5-box.open {
    opacity: 1;
    z-index: 50;
    visibility: visible;
  }
  .dialog-h5 {
    display: flex;
    position: relative;
    /* min-width: 360px; */
    margin: auto;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 10px);
    border-radius: 3px;
    background-color: #fff;
    opacity: 0;
    transform: scale(0.5);
    transition: .3s cubic-bezier(.645, .045, .355, 1);
  }
  .dialog-h5-box.open .dialog-h5 {
    opacity: 1;
    transform: scale(1);
  }
  .dialog-h5-body {
    position: relative;
    box-sizing: border-box;
    display: flex;
    width: 80vw;
    padding: 10px;
    flex: 1;
    flex-direction: column;
  }
  .dialog-h5-btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
    border: 0;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
  `
  // 如果组件中用到了弹窗 把弹窗的样式也加上
  if (code.includes('dialog-box')) {
    styleCode += pcDialogStyle
  } else if (code.includes('dialog-h5-box')) {
    styleCode += mobileDialogStyle
  }

  styleCode = await window.browserifyProcessCss(styleCode)

  return `
    <div class="${isMobile ? `pt-mobile-el-list-${page_id}` : `pt-pc-el-list-${page_id}`}">
      <style>${styleCode}</style>
      ${code}
    </div>
  `
}

async function getCodeStyle({ config, page_id }) {
  // 如果遇到找不到的元素 需要给出提示
  if (!map[config.name] || !map[config.name].staticRender) return { styles: [], html: '' }
  // 首先拿到父元素的样式和代码
  let { styles, html } =await map[config.name].staticRender({ config, page_id })
  let childrenHtml = ''
  if (config.children && config.children.length) {
    for (const childConfig of config.children) {
      const { styles: childStyles, html: childHtml } = await getCodeStyle({ config: childConfig, page_id })
      childrenHtml += childHtml
      styles.push(...childStyles)
    }
  }
  // 如果有子元素 需要把子元素渲染放在内部
  html = html.replace('{{CHILDREN_PLACEHOLDER}}', childrenHtml)
  return { styles, html }
}
